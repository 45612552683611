import { $$ } from '../helpers/query-selector';

const timelineItems = $$('.js-timeline-item');
const timelineItemClass = 'l-timeline__item--active';

function initTimeline() {
  for (const item of timelineItems) {
    const timelineOserver = new IntersectionObserver(
      (entries, observer) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            entry.target.classList.add(timelineItemClass);
          } else {
            entry.target.classList.remove(timelineItemClass);
          }
        }
      },
      { rootMargin: `0% 0% -42% 0%` }
    );

    timelineOserver.observe(item);
  }
}

export default initTimeline;
