import '../scss/app.scss'; // Vite requires css import in app.js

import { $ } from './helpers/query-selector';
import initLazyLoad from './modules/lazyload';
import initSetVh from './modules/set-vh';
import timeline from './modules/timeline';

function init() {
  initLazyLoad();
  initSetVh();
  timeline();

  if ($('.js-iframe')) import('./modules/iframe').then(m => m.default());

  if ($('.js-lottie')) import('./modules/lottie').then(m => m.default());

  if ($('.js-form')) import('./modules/form').then(m => m.default());

  if ($('.js-textarea')) import('./modules/textarea').then(m => m.default());

  if ($('.js-countdown')) import('./modules/countdown').then(m => m.default());

  if ($('.js-counter')) import('./modules/counter').then(m => m.default());

  if ($('.js-locations-more'))
    import('./modules/locations').then(m => m.default());
}

init();
